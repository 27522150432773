import React from "react";

const AccountSettingsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1 9.221C18.29 9.221 17.55 7.941 18.45 6.371C18.97 5.461 18.66 4.301 17.75 3.781L16.02 2.791C15.23 2.321 14.21 2.601 13.74 3.391L13.63 3.581C12.73 5.151 11.25 5.151 10.34 3.581L10.23 3.391C10.1222 3.20198 9.97789 3.03629 9.80545 2.90357C9.63302 2.77084 9.43591 2.67374 9.2256 2.6179C9.01529 2.56207 8.79597 2.54862 8.58041 2.57833C8.36485 2.60805 8.15736 2.68034 7.97 2.791L6.24 3.781C5.33 4.301 5.02 5.471 5.54 6.381C6.45 7.941 5.71 9.221 3.9 9.221C2.86 9.221 2 10.071 2 11.121V12.881C2 13.921 2.85 14.781 3.9 14.781C5.71 14.781 6.45 16.061 5.54 17.631C5.02 18.541 5.33 19.701 6.24 20.221L7.97 21.211C8.76 21.681 9.78 21.401 10.25 20.611L10.36 20.421C11.26 18.851 12.74 18.851 13.65 20.421L13.76 20.611C14.23 21.401 15.25 21.681 16.04 21.211L17.77 20.221C18.68 19.701 18.99 18.531 18.47 17.631C17.56 16.061 18.3 14.781 20.11 14.781C21.15 14.781 22.01 13.931 22.01 12.881V11.121C22.0048 10.617 21.8015 10.1352 21.4442 9.77977C21.0868 9.4243 20.604 9.2236 20.1 9.221ZM12 15.251C10.21 15.251 8.75 13.791 8.75 12.001C8.75 10.211 10.21 8.751 12 8.751C13.79 8.751 15.25 10.211 15.25 12.001C15.25 13.791 13.79 15.251 12 15.251Z"
        fill="white"
      />
    </svg>
  );
};

export default AccountSettingsIcon;
