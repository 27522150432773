import React from "react";

const ManageGroomIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.19 2.21C22.154 2.11963 22.1 2.03754 22.0312 1.96876C21.9625 1.89998 21.8804 1.84596 21.79 1.81C21.6983 1.771 21.5997 1.75061 21.5 1.75H15C14.59 1.75 14.25 2.09 14.25 2.5C14.25 2.91 14.59 3.25 15 3.25H19.69L15.18 7.77H15.17C13.7878 6.62218 12.0466 5.99579 10.25 6C8.7172 6 7.21882 6.45453 5.94433 7.30611C4.66985 8.15769 3.67652 9.36808 3.08994 10.7842C2.50336 12.2003 2.34988 13.7586 2.64892 15.2619C2.94795 16.7653 3.68607 18.1462 4.76993 19.2301C5.85378 20.3139 7.2347 21.052 8.73805 21.3511C10.2414 21.6501 11.7997 21.4966 13.2158 20.9101C14.6319 20.3235 15.8423 19.3302 16.6939 18.0557C17.5455 16.7812 18 15.2828 18 13.75C18 11.88 17.34 10.17 16.23 8.83L20.75 4.31V9C20.75 9.41 21.09 9.75 21.5 9.75C21.91 9.75 22.25 9.41 22.25 9V2.5C22.25 2.4 22.23 2.31 22.19 2.21Z"
        fill="white"
      />
    </svg>
  );
};

export default ManageGroomIcon;
