import React from "react";

const PasswordIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7892 4.22C16.8292 1.27 12.0292 1.27 9.08917 4.22C7.01917 6.27 6.39917 9.22 7.19917 11.82L2.49917 16.52C2.16917 16.86 1.93917 17.53 2.00917 18.01L2.30917 20.19C2.41917 20.91 3.08917 21.59 3.80917 21.69L5.98917 21.99C6.46917 22.06 7.13917 21.84 7.47917 21.49L8.29917 20.67C8.49917 20.48 8.49917 20.16 8.29917 19.96L6.35917 18.02C6.21969 17.8789 6.14147 17.6884 6.14147 17.49C6.14147 17.2916 6.21969 17.1011 6.35917 16.96C6.64917 16.67 7.12917 16.67 7.41917 16.96L9.36917 18.91C9.55917 19.1 9.87917 19.1 10.0692 18.91L12.1892 16.8C14.7792 17.61 17.7292 16.98 19.7892 14.93C22.7392 11.98 22.7392 7.17 19.7892 4.22ZM14.4992 12C13.8361 12 13.2002 11.7366 12.7314 11.2678C12.2626 10.7989 11.9992 10.163 11.9992 9.5C11.9992 8.83696 12.2626 8.20107 12.7314 7.73223C13.2002 7.26339 13.8361 7 14.4992 7C15.1622 7 15.7981 7.26339 16.2669 7.73223C16.7358 8.20107 16.9992 8.83696 16.9992 9.5C16.9992 10.163 16.7358 10.7989 16.2669 11.2678C15.7981 11.7366 15.1622 12 14.4992 12Z"
        fill="black"
      />
    </svg>
  );
};

export default PasswordIcon;
